import store from "@/state/store.js";
import formatInputDate from "@/helpers/formatInputDate";
import groupModal from "./createNewGroupModal.vue";
let $ = require("jquery");
// TODO: VALIDATE PHONE NUMBER AS USED IN SIGN AND SIGNUP
export default {
  name: "create-new-group",
  data() {
    return {
      success: "",
      errors: [],
      loading: false,
      type: "alert-danger",
      formData: {
        firstname: "",
        lastname: "",
        name: "",
        date_of_birth: "",
        group_id: ""
      },
      rules: {
        firstname: [{
          required: true,
          message: "Firstname is required.",
          trigger: "change"
        }],
        lastname: [{
          required: false,
          message: "Lastname is required.",
          trigger: "change"
        }],
        group_id: [{
          required: true,
          message: "Select a group.",
          trigger: "change"
        }],
        // email: [
        //     {required: true, message: 'Please Enter email.', trigger: 'change' },
        //     { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
        // ],
        phone: [{
          required: true,
          message: "Phone is required.",
          trigger: "change"
        }, {
          pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/g,
          message: "Enter a valid phone number",
          trigger: "change"
        }, {
          min: 10,
          max: 15,
          message: `Length should be greater than 10`,
          trigger: "blur"
        }]
        // date_of_birth: [
        //     {
        //         required: true,message:'Date of birth is required.', trigger: 'change'
        //     }
        // ]
      },
      newGroupFromContacts: "",
      loadingGroupMembers: false,
      groups: [],
      page: 1,
      loadingGroupMembers: false,
      totalGroupMembers: 0
    };
  },
  mounted() {
    return store.getters["groups/all_groups"];
  },
  created() {
    store.dispatch("groups/listGroups", this.page).then(response => {
      this.fetchingGroup = false;
      let data = response.data.data.data;
      this.totalGroupMembers = response.data.data.total;
      this.groups = data;
      this.page = response.data.data.current_page + 1;
    }).catch(err => this.fetchingGroup = false);
  },
  computed: {
    contactForm() {
      return store.getters["uistate/contactForm"];
    },
    foundGroups() {
      return this.groups;
    }
  },
  methods: {
    createNewContact() {
      if (this.title && this.title != "") {}
    },
    hideModal() {
      $("#contact").modal("hide");
      this.success = "";
      this.errors = "";
      this.formData = {
        firstname: "",
        lastname: "",
        name: "",
        date_of_birth: "",
        group_id: ""
      };
      // // Emit Done Adding Contact to parent for refresh
      // this.$emit('doneAddingContact')
    },
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.errors = "";
          this.loading = true;
          let {
            date_of_birth,
            email,
            phone,
            firstname,
            lastname,
            group_id
          } = this.formData;
          let date = formatInputDate(this.formData.date_of_birth);
          let payload = {
            firstname: firstname,
            lastname: lastname,
            date_of_birth: date,
            phone: phone,
            email: email,
            group_id: group_id
          };
          store.dispatch("contacts/createContact", payload).then(response => {
            this.success = response.data.message, this.$emit("updateContact", response.data.data), this.formData = {
              firstname: "",
              lastname: "",
              day: "",
              month: "",
              year: "",
              name: "",
              group_id: ""
            };
            // Emit Done Adding Contact to parent for refresh
            this.$emit("doneAddingContact");
          }).catch(err => {
            if (err.response.data == undefined) {
              this.errors = ["Network error try again"];
            }
            if (err.response && err.response.status === 404) {
              this.errors = err.response.data.data.error;
            }
          }).finally(() => this.loading = false);
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    addNewGroup() {
      store.dispatch("uistate/addGroupFormData", {
        title: "Create new group",
        mainModalType: "new",
        type: "new",
        btnTitle: "Create new group"
      });
      let element = this.$refs.groupModal.$el;
      $(element).modal("show");
      let myDiv = this.$refs.mainAddContact;
      myDiv.classList.toggle("is-fade");
    },
    removeOpacity() {
      let myDiv = this.$refs.mainAddContact;
      myDiv.classList.remove("is-fade");

      //  recall the groups
      store.dispatch("groups/listGroups", 1).then(response => {
        this.fetchingGroup = false;
        let data = response.data.data.data;
        this.totalGroupMembers = response.data.data.total;
        this.groups = data;
        this.page = response.data.data.current_page + 1;
      }).catch(err => this.fetchingGroup = false);
    },
    useInfiniteLoading() {
      if (this.loadingGroupMembers) {
        return;
      }
      this.loadingGroupMembers = true;
      if (this.groups.length === this.totalGroupMembers) {
        this.loadingGroupMembers = false;
        return;
      }
      // Increment this.page only if there is more data to load
      if (this.groups.length < this.totalGroupMembers) {
        store.dispatch("groups/listGroups", this.page).then(response => {
          let data = response.data.data.data;

          // Filter out duplicates before appending
          data = data.filter(group => !this.groups.find(existingGroup => existingGroup.id === group.id));
          if (data.length > 0) {
            // Append filtered data to this.groups
            this.groups = [...this.groups, ...data];
          }

          // Increment this.page here
          this.page = response.data.data.current_page + 1;
        }).finally(() => {
          this.loadingGroupMembers = false;
        });
      }
    }
  },
  components: {
    groupModal
  }
};