var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "modal fade",
    attrs: {
      "id": "groupmodal",
      "tabindex": "-1",
      "role": "dialog",
      "aria-labelledby": "groupmodal",
      "aria-hidden": "true"
    }
  }, [_c('div', {
    staticClass: "modal-dialog modal-dialog-centered",
    attrs: {
      "role": "document"
    }
  }, [_vm.groupSuccess ? _c('div', {
    staticClass: "modal-content d-flex align-items-center"
  }, [_c('successMessage', {
    ref: "groupSuccess",
    attrs: {
      "message": _vm.groupSuccess
    }
  }), _c('button', {
    staticClass: "__close__success__modal",
    on: {
      "click": _vm.hideGroupModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("misc.button.close")) + " ")])], 1) : _vm._e(), !_vm.groupSuccess && _vm.groupFormType.mainModalType == 'new' ? _c('div', {
    staticClass: "modal-content"
  }, [_vm.groupFormType.type != 'delete' ? _c('div', {
    staticClass: "modal-header pt-4 pb-2"
  }, [_c('h5', {
    staticClass: "modal-title",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_vm._v(" " + _vm._s(_vm.groupFormType.title) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_vm.groupFormType.type != 'delete' ? _c('div', {
    staticClass: "col-md-12"
  }, [_c('el-form', {
    ref: "ruleForm",
    attrs: {
      "model": _vm.groupFormData,
      "rules": _vm.groupRules
    },
    nativeOn: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.checkEnter.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form_input_container"
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.$t('dashboard.contacts.excel.group_name'),
      "prop": "name"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('dashboard.contacts.excel.placeholder'),
      "autocomplete": "false"
    },
    model: {
      value: _vm.groupFormData.name,
      callback: function ($$v) {
        _vm.$set(_vm.groupFormData, "name", $$v);
      },
      expression: "groupFormData.name"
    }
  })], 1)], 1)])], 1) : _vm._e(), _vm.groupFormType.type == 'delete' ? _c('div', {
    staticClass: "continue_edit_header pb-2"
  }, [_vm._m(0), _c('h3', {
    staticClass: "continue_edit_header_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.contacts.excel.delete")) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "col-md-12"
  }, [_vm.groupError ? _c('Alert', {
    staticClass: "alert-type",
    attrs: {
      "message": _vm.groupError,
      "type": _vm.type
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "modal__footer__btns"
  }, [_vm.groupFormType.type == 'delete' ? _c('div', {
    staticClass: "deleteWrapper"
  }, [_vm.groupFormType.type == 'delete' ? _c('el-button', {
    staticStyle: {
      "margin-right": "1.5em",
      "padding-right": "2em",
      "padding-left": "2em"
    },
    attrs: {
      "id": "",
      "loading": _vm.groupLoading,
      "type": "danger"
    },
    on: {
      "click": _vm.deleteGroup
    }
  }, [_vm._v(" " + _vm._s(_vm.groupFormType.btnTitle) + " ")]) : _vm._e(), _c('el-button', {
    staticClass: "notranslate",
    staticStyle: {
      "padding-right": "2em",
      "padding-left": "2em"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.hideGroupModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("misc.button.cancel")) + " ")])], 1) : _vm._e(), _vm.groupFormType.type != 'delete' ? _c('button', {
    staticClass: "cancel notranslate",
    on: {
      "click": _vm.hideGroupModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("misc.button.cancel")) + " ")]) : _vm._e(), _vm.groupFormType.type == 'new' ? _c('el-button', {
    attrs: {
      "id": "__create__btn",
      "loading": _vm.groupLoading
    },
    on: {
      "click": function ($event) {
        return _vm.createNewGroup('ruleForm');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/3 User.svg"),
      "alt": "user icon"
    }
  }), _vm._v(" " + _vm._s(_vm.groupFormType.btnTitle) + " ")]) : _vm._e(), _vm.groupFormType.type == 'edit' ? _c('el-button', {
    attrs: {
      "type": "primary",
      "loading": _vm.groupLoading
    },
    on: {
      "click": function ($event) {
        return _vm.editGroup('ruleForm');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.groupFormType.btnTitle) + " ")]) : _vm._e()], 1)])]) : _vm._e(), !_vm.groupSuccess && _vm.groupFormType.mainModalType === 'import' ? _c('div', {
    staticClass: "modal-content"
  }, [_c('div', {
    staticClass: "modal-header pt-4 pb-2 mb-5 modal__padding",
    staticStyle: {
      "margin-right": "0px !important",
      "margin-left": "0px !important"
    }
  }, [_c('h5', {
    staticClass: "modal-title title-is-centered",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_c('img', {
    attrs: {
      "width": "23px",
      "src": require("../../../assets/vscode-icons_file-type-excel.svg"),
      "alt": ""
    }
  }), _vm._v("   " + _vm._s(_vm.$t("dashboard.contacts.excel.import")) + " ")])]), _vm.groupFormType.importExcelContentType == 'main' ? _c('div', {
    staticClass: "modal-body modal__padding"
  }, [_vm._m(1), _c('div', {
    staticClass: "main_text"
  }, [_c('h4', {
    staticClass: "uppercase"
  }, [_c('strong', {
    staticClass: "note-excel"
  }, [_vm._v(_vm._s(_vm.$t("dashboard.send_message.sms.excel_sms.note.please_note")))])]), _c('p', [_vm._v(" " + _vm._s(_vm.$t("dashboard.contacts.excel.note.header")) + " "), _vm._m(2)]), _c('h5', {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.sms.excel_sms.note.max")) + " "), _c('span', {
    staticClass: "note-excel"
  }, [_vm._v(" 5MB ")])])]), _c('div', {
    staticClass: "text-center"
  }, [_c('a', {
    staticClass: "__cancel",
    attrs: {
      "href": _vm.importExcel,
      "download": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("dashboard.contacts.excel.note.download")))])]), _c('div', {
    staticClass: "modal__footer__btns"
  }, [_c('button', {
    staticClass: "cancel notranslate",
    on: {
      "click": _vm.hideGroupModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("misc.button.cancel")) + " ")]), _c('el-button', {
    attrs: {
      "id": "__large__btn",
      "type": "primary"
    },
    on: {
      "click": _vm.selectGroup
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/3 User.svg"),
      "alt": ""
    }
  }), _vm._v("   " + _vm._s(_vm.$t("dashboard.contacts.excel.import")) + " ")])], 1)]) : _vm._e(), _vm.groupFormType.importExcelContentType === 'selectgroup' ? _c('div', {}, [_c('div', {
    staticClass: "modal-body modal__padding"
  }, [_c('div', {
    staticClass: "__select__group__main"
  }, [_c('div', {
    staticClass: "_select_group_inner"
  }, [Array.isArray(_vm.fetchedGroups) && _vm.fetchedGroups.length == 0 ? _c('div', {
    staticClass: "__show__no__group__message text-center"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("dashboard.contacts.excel.no_groups")))]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.addNewGroup
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.groups.title")) + " ")])], 1) : _vm._e(), Array.isArray(_vm.fetchedGroups) && _vm.fetchedGroups.length > 0 ? _c('div', {
    staticClass: "row",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('el-form', {
    ref: "ruleform",
    attrs: {
      "model": _vm.importContactForm
    },
    nativeOn: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.checkEnter.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form_input_container"
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.$t('dashboard.groups.select_group_excel'),
      "prop": "group_id",
      "rules": [{
        required: true,
        message: 'Please enter group ID',
        trigger: ['blur', 'change']
      }]
    }
  }, [_c('el-select', {
    staticClass: "is-el-select",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "filterable": "",
      "placeholder": _vm.$t('dashboard.groups.choose')
    },
    model: {
      value: _vm.importContactForm.group_id,
      callback: function ($$v) {
        _vm.$set(_vm.importContactForm, "group_id", $$v);
      },
      expression: "importContactForm.group_id"
    }
  }, [_c('div', {
    directives: [{
      name: "infinite-scroll",
      rawName: "v-infinite-scroll",
      value: _vm.useInfiniteLoading,
      expression: "useInfiniteLoading"
    }],
    staticClass: "infinite-scroll"
  }, [_vm._l(_vm.groups, function (group, key) {
    return _c('el-option', {
      key: key,
      attrs: {
        "label": `${group.name}`,
        "value": group.id
      }
    });
  }), _vm.loadingGroupMembers ? _c('p', {
    staticClass: "loading-state"
  }, [_vm._v(" Loading... ")]) : _vm._e(), _vm.groups.length > 0 && _vm.groups.length == _vm.totalGroupMembers ? _c('p', {
    staticClass: "loading-state"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.sms.group_sms.all_loaded")) + " ")]) : _vm._e()], 2), _c('div', {
    staticStyle: {
      "display": "flex",
      "width": "100%",
      "margin": "1rem auto",
      "justify-content": "center"
    }
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.addNewGroup
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.groups.title")) + " ")])], 1)])], 1)], 1)])], 1)]) : _vm._e(), _c('div', {
    staticClass: "modal__footer__btns px-3",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('button', {
    staticClass: "cancel notranslate",
    on: {
      "click": _vm.hideGroupModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("misc.button.cancel")) + " ")]), Array.isArray(_vm.fetchedGroups) && _vm.fetchedGroups.length > 0 ? _c('el-button', {
    staticStyle: {
      "padding-right": "3em",
      "color": "#fff",
      "padding-left": "3em"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.nextToimportGroup('ruleform');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("misc.button.next")))]) : _vm._e()], 1)])])])]) : _vm._e(), _vm.groupFormType.importExcelContentType == 'excelimport' ? _c('div', {
    staticClass: "modal-body d-flex flex-column align-items-center justify-content-center el-upload-centered"
  }, [_c('el-upload', {
    staticClass: "avatar-uploader",
    staticStyle: {
      "cursor": "pointer",
      "position": "relative",
      "overflow": "hidden",
      "width": "100%",
      "display": "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center"
    },
    attrs: {
      "action": "#",
      "show-file-list": true,
      "file-list": _vm.fileList,
      "auto-upload": false,
      "accept": ".xlsx, .xls, .csv",
      "multiple": false,
      "drag": "",
      "on-change": _vm.handleChange
    }
  }, [_c('div', {
    staticClass: "el-upload__text"
  }, [_c('p', {
    staticClass: "__upload__audio__file"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.sms.excel_sms.import")) + " ")]), _c('p', {
    staticClass: "file_type"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.sms.excel_sms.type")) + " ")]), _c('img', {
    attrs: {
      "src": require("../../../assets/vscode-icons_file-type-excel.svg"),
      "alt": "excel icon"
    }
  }), _c('p', {
    staticClass: "drag_file"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.sms.excel_sms.drag_or")) + " "), _c('em', [_vm._v(" " + _vm._s(_vm.$t("dashboard.send_message.sms.excel_sms.browse")))])])])]), _vm.fileError ? _c('div', {
    staticClass: "col-md-12 mt-2"
  }, [_c('Alert', {
    staticClass: "alert-type",
    attrs: {
      "message": _vm.fileError,
      "type": 'alert-danger'
    }
  })], 1) : _vm._e(), _vm.errors.length ? _c('div', {
    staticClass: "col-md-12 mt-2"
  }, _vm._l(_vm.errors, function (error, key) {
    return _c('Alert', {
      key: key,
      staticClass: "alert-type",
      attrs: {
        "message": error,
        "type": 'alert-danger'
      }
    });
  }), 1) : _vm._e(), _c('div', {
    staticClass: "d-flex my-5 justify-content-between",
    staticStyle: {
      "width": "100% !important"
    }
  }, [_c('button', {
    staticClass: "cancel",
    on: {
      "click": _vm.backToSelectGroup
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("misc.button.back")) + " ")]), _vm.fileUploadSuccess ? _c('el-button', {
    staticStyle: {
      "height": "43px"
    },
    attrs: {
      "loading": _vm.groupLoading,
      "type": "primary"
    },
    on: {
      "click": _vm.importContacts
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.contacts.excel.note.import")) + " ")]) : _vm._e()], 1)], 1) : _vm._e()]) : _vm._e()])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "_edit_icon"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/Delete.svg"),
      "height": "33px",
      "width": "31px",
      "alt": "bin icon for delete"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "sample_excel table-responsive-md"
  }, [_c('table', {
    staticClass: "table table-borderless example-table"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("A")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("B")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("C")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("D")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("E")])])]), _c('tbody', [_c('tr', [_c('th', {
    staticClass: "excel-header-example",
    attrs: {
      "scope": "row"
    }
  }, [_vm._v("firstname")]), _c('td', {
    staticClass: "excel-header-example"
  }, [_vm._v("lastname")]), _c('td', {
    staticClass: "excel-header-example"
  }, [_vm._v("phone")]), _c('td', {
    staticClass: "excel-header-example"
  }, [_vm._v("email")]), _c('td', {
    staticClass: "excel-header-example"
  }, [_vm._v("date_of_birth")])]), _c('tr', [_c('th', {
    attrs: {
      "scope": "row"
    }
  }, [_vm._v("George")]), _c('td', [_vm._v("Darko")]), _c('td', [_vm._v("0202346754")]), _c('td', [_vm._v("darko@mail.com")]), _c('td', [_vm._v("19/09/98")])]), _c('tr', {
    staticClass: "tr_stripped"
  }, [_c('th', {
    attrs: {
      "scope": "row"
    }
  }, [_vm._v("George")]), _c('td', [_vm._v("Darko")]), _c('td', [_vm._v("0202346754")]), _c('td', [_vm._v("darko@mail.com")]), _c('td', [_vm._v("19/09/98")])]), _c('tr', [_c('th', {
    attrs: {
      "scope": "row"
    }
  }, [_vm._v("George")]), _c('td', [_vm._v("Darko")]), _c('td', [_vm._v("0202346754")]), _c('td', [_vm._v("darko@mail.com")]), _c('td', [_vm._v("19/09/98")])]), _c('tr', {
    staticClass: "tr_stripped"
  }, [_c('th', {
    attrs: {
      "scope": "row"
    }
  }, [_vm._v("George")]), _c('td', [_vm._v("Darko")]), _c('td', [_vm._v("0202346754")]), _c('td', [_vm._v("darko@mail.com")]), _c('td', [_vm._v("19/09/98")])])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', {
    staticClass: "required-header-names notranslate"
  }, [_c('br'), _vm._v(" firstname, lastname, phone, email, date_of_birth.")]);
}];
export { render, staticRenderFns };