import store from "@/state/store.js";
let $ = require("jquery");
export default {
  name: "create-new-group",
  props: {
    editFormData: {
      required: false
    },
    createNewGroupContacts: {
      required: false
    }
  },
  data() {
    return {
      errors: [],
      loading: false,
      success: "",
      type: "alert-danger",
      inputFormData: {
        name: "",
        description: ""
      },
      rules: {
        name: [{
          required: true,
          message: "Group name is required",
          trigger: "blur"
        }]
      }
    };
  },
  computed: {
    groupForm() {
      return store.getters["uistate/groupFormData"];
    },
    groups() {
      return store.getters["groups/groups"];
    }
  },
  methods: {
    hideModal() {
      $("#group").modal("hide");
      this.inputFormData = {
        name: "",
        description: ""
      };
      this.$emit("hiddenModal");
    },
    createNewGroup(formName) {
      this.errors = "";
      this.loading = true;
      this.$refs[formName].validate(valid => {
        if (valid) {
          store.dispatch("groups/createGroup", this.inputFormData).then(response => {
            this.success = response.data.message;
            this.inputFormData = {
              name: "",
              description: ""
            };
            // Take 5 seconds then hide the success message
            setTimeout(() => {
              this.success = "";
            }, 5000);
          }).catch(err => {
            var _err$response;
            if (((_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
              this.errors = ["Network error try again"];
            }
            if (err.response && err.response.status === 404) {
              this.errors = err.response.data.data.error;
            }
          }).finally(() => {
            this.loading = false;
            store.dispatch("groups/listGroups", 1);
          });
        } else {
          this.loading = false;
          return false;
        }
      });
    }
  }
};