import { render, staticRenderFns } from "./createNewGroupModal.vue?vue&type=template&id=185f1111&scoped=true"
import script from "./createNewGroupModal.vue?vue&type=script&lang=js"
export * from "./createNewGroupModal.vue?vue&type=script&lang=js"
import style0 from "./createNewGroupModal.vue?vue&type=style&index=0&id=185f1111&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "185f1111",
  null
  
)

export default component.exports