import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import store from "@/state/store.js";
let $ = require("jquery");
import { read, utils } from "xlsx";
export default {
  name: "import-contact-from-excel",
  props: ["edit"],
  data() {
    return {
      selectGroupForm: {
        group_id: ""
      },
      selectgrouprules: {
        group_id: [{
          required: true,
          message: this.$t("misc.form.required"),
          trigger: "change"
        }]
      },
      fileList: [],
      groupLoading: false,
      groupError: "",
      type: "alert-danger",
      groupSuccess: "",
      edittingGroup: {},
      importExcelContentType: "",
      fileUploadSuccess: false,
      fileList: [],
      fileError: "",
      errors: "",
      // groupFormType:{
      //     title:"Create new group",
      //     btnTitle:"Create new group",
      //     type:'new'

      // },
      groupFormData: {
        name: "",
        group_id: "",
        key: ""
      },
      importContactForm: {
        group_id: "",
        import_contact_csv: ""
      },
      importContactRules: {
        group_id: [{
          required: true,
          message: this.$t("misc.form.required"),
          trigger: "change"
        }]
      },
      groupRules: {
        import_contact_csv: [{
          required: true,
          message: this.$t("misc.form.required"),
          trigger: "change"
        }]
      },
      groups: [],
      page: 1,
      loadingGroupMembers: false,
      totalGroupMembers: 0,
      success: "rest"
    };
  },
  updated() {
    if (this.edit) {
      this.groupFormData = this.edit;
    }
  },
  computed: {
    importExcel() {
      return process.env.VUE_APP_ENGINE_URL + "/storage/contacts_import.xlsx";
    },
    fetchedGroups() {
      return store.getters["groups/all_groups"];
    },
    fetchingData() {
      return store.getters["groups/fetchingData"];
    },
    groupFormType() {
      return store.getters["uistate/groupFormData"];
    }
  },
  methods: {
    downloadExcel() {
      const url = URL.createObjectURL("https://prodapi.mnotify.com/storage/contacts_import.xlsx");
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `sample_excel.xlsx`);
      document.body.appendChild(link);
      link.click();
    },
    backToSelectGroup() {
      this.selectGroup();
    },
    importContacts() {
      this.groupSuccess = "";
      this.groupError = "";
      this.groupLoading = "";
      const formData = new FormData();
      formData.append("group_id", this.importContactForm.group_id);
      formData.append("import_contact_csv", this.fileList[0]);
      store.dispatch("contacts/importContact", formData).then(response => {
        this.$alert(response.data.message, this.$t("misc.toast.success"), {
          type: "success",
          closeOnClickModal: true
        });
        if (Array.isArray(response.data)) {
          this.errors = response.data[0].errors;
        } else {
          this.$emit("addContactSuccess");
          this.$emit("cancel");
        }
      }).catch(err => {
        var _err$response;
        if (((_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
          this.groupError = [this.$t("misc.network_error")];
          this.errors = [his.$t("misc.network_error")];
        }
        if (err.response.status === 404) {
          this.groupError = [err.response.data.message];
          this.errors = [err.response.data.message];
        }
      }).finally(() => {
        this.groupLoading = false;
      });
    },
    handleFileExceed() {
      this.$message.error(this.$t("dashboard.send_message.sms.excel_sms.note.min_file"));
    },
    // handleChange(file, fileList){
    //     this.fileList = [file.raw]
    //     this.fileUploadSuccess = true
    // },
    async handleChange(file, fileList) {
      this.fileList = [file.raw];
      this.fileError = "";
      this.errors = "";
      const f = await file.raw.arrayBuffer();
      const wb = read(f);
      const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
        raw: false
      });
      // let parsedData = await xlsxParser.onFileSelection(await file.raw, { showNullProperties: false, hideEmptyRows: true })
      // Use new parser
      let excelSheet = data; //parsedData.Sheet1
      if (!excelSheet.length) {
        this.fileError = this.$t("dashboard.send_message.sms.excel_sms.note.min_two");
        return;
      }
      //  let headers = Object.keys(excelSheet[0])
      // // let expectedHeaders = ['firstname', 'lastname','phone','email','date_of_birth']
      // if(headers !== expectedHeaders) {
      //     this.fileError = `Headers should be ['firstname', 'lastname','phone','email','date_of_birth'] format.`
      // }
      this.fileUploadSuccess = true;
    },
    handleError(err, file, fileLis) {
      this.$message.error(this.$t("misc.general_error"));
    },
    handleSuccess(file) {
      this.groupSuccess = this.$t("dashboard.groups.group_success");
    },
    nextToimportGroup(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          store.dispatch("uistate/addGroupFormData", {
            title: "",
            mainModalType: "import",
            type: "import",
            btnTitle: this.$t("dashboard.contacts.import_excel_sheet"),
            importExcelContentType: "excelimport"
          });
        } else {
          return false;
        }
      });
    },
    addNewGroup(type) {
      store.dispatch("uistate/addGroupFormData", {
        title: this.$t("dashboard.groups.create_new"),
        mainModalType: "new",
        type: "new",
        btnTitle: this.$t("dashboard.groups.create_new"),
        from: "from_select_contacts"
      });
    },
    selectGroup() {
      this.importContactForm.group_id = "";
      store.dispatch("uistate/addGroupFormData", {
        title: "",
        mainModalType: "import",
        type: "import",
        btnTitle: this.$t("dashboard.contacts.import_excel_sheet"),
        importExcelContentType: "selectgroup"
      });
    },
    resetGroupState() {
      this.groupFormData.name = null;
      this.groupSuccess = "";
      this.groupError = "", this.groupLoading = false;
      this.fileList = [];
      this.fileError = "";
      this.selectGroupForm = {
        group_id: ""
      };
    },
    hideGroupModal() {
      this.resetGroupState();
      this.$emit("cancel");
    },
    // API ACTIONS
    deleteGroup() {
      this.groupError = "";
      this.groupLoading = true;
      store.dispatch("groups/deleteGroup", this.groupFormData).then(response => {
        this.groupSuccess = response.data.message;
        this.groupLoading = false;
        this.$emit("RefreshComponentFromExcel");
      }).catch(err => {
        var _err$response2;
        this.groupLoading = false;
        if (((_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.data) == undefined) {
          this.groupError = this.$t("misc.network_error");
        }
        if (err.response && err.response.status === 404) {
          this.groupError = err.response.data.message;
        }
      }).finally(() => {
        this.$emit("addContactSuccess");
      });
    },
    editGroup(formName) {
      this.groupError = "";
      this.groupLoading = true;
      this.$refs[formName].validate(valid => {
        if (valid) {
          store.dispatch("groups/updateGroup", this.groupFormData).then(response => {
            this.groupSuccess = response.data.message;
            this.groupLoading = false;
            this.groupFormData.name = "";
            this.$emit("RefreshComponentFromExcel");
          }).catch(err => {
            var _err$response3;
            this.groupLoading = false;
            if (((_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : _err$response3.data) == undefined) {
              this.groupError = this.$t("misc.network_error");
            }
            if (err.response && err.response.status === 404) {
              this.groupError = err.response.data.message;
            }
          }).finally(() => {
            this.$emit("addContactSuccess");
          });
        } else {
          this.groupLoading = false;
          return false;
        }
      });
    },
    createNewGroup(formName) {
      // find create new group
      this.groupError = "";
      this.groupLoading = true;
      this.$refs[formName].validate(valid => {
        if (valid) {
          store.dispatch("groups/createGroup", this.groupFormData).then(response => {
            this.groupLoading = false;
            this.groupFormData.name = null;
            let groupForm = this.groupFormType;
            if (groupForm && groupForm.from == "from_select_contacts") {
              return this.selectGroup();
            }
            this.groupSuccess = response.data.message;
          }).catch(err => {
            var _err$response4;
            this.groupLoading = false;
            if (((_err$response4 = err.response) === null || _err$response4 === void 0 ? void 0 : _err$response4.data) == undefined) {
              this.groupError = this.$t("misc.network_error");
            }
            if (err.response && err.response.status === 404) {
              this.groupError = err.response.data.message;
            }
          }).finally(() => {
            this.$emit("addContactSuccess");
          });
        } else {
          this.groupLoading = false;
          return false;
        }
      });
    },
    useInfiniteLoading() {
      if (this.loadingGroupMembers) {
        return;
      }
      this.loadingGroupMembers = true;
      if (this.groups.length === this.totalGroupMembers) {
        this.loadingGroupMembers = false;
        return;
      }

      // Increment this.page only if there is more data to load
      if (this.groups.length < this.totalGroupMembers) {
        store.dispatch("groups/listGroups", this.page).then(response => {
          let data = response.data.data.data;

          // Filter out duplicates before appending
          data = data.filter(group => !this.groups.find(existingGroup => existingGroup.id === group.id));
          if (data.length > 0) {
            // Append filtered data to this.groups
            this.groups = [...this.groups, ...data];
          }

          // Increment this.page here
          this.page = response.data.data.current_page + 1;
        }).finally(() => {
          this.loadingGroupMembers = false;
        });
      }
    }
  },
  created() {
    store.dispatch("groups/listGroups", this.page).then(response => {
      this.fetchingGroup = false;
      let data = response.data.data.data;
      this.totalGroupMembers = response.data.data.total;
      this.groups = data;
      this.page = response.data.data.current_page + 1;
    }).catch(err => this.fetchingGroup = false);
    // if group success is active and user clicks outside the modal close it
    document.addEventListener("click", e => {
      if (this.groupSuccess && !this.$refs.groupSuccess.$el.contains(e.target)) {
        this.hideGroupModal();
      }
    });
  }
};