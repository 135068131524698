var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "modal fade",
    attrs: {
      "id": "group",
      "tabindex": "-1",
      "role": "dialog",
      "aria-labelledby": "group",
      "aria-hidden": "true"
    }
  }, [_c('div', {
    staticClass: "modal-dialog modal-dialog-centered",
    attrs: {
      "role": "document"
    }
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('button', {
    staticClass: "x-cancel",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v("X")]), _c('div', {
    staticClass: "modal-header pt-4 pb-2"
  }, [_c('h5', {
    staticClass: "modal-title",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_vm._v(" " + _vm._s(_vm.groupForm.title) + " ")])]), _c('div', {
    staticClass: "modal-body m-0 p-0"
  }, [_c('div', {
    staticClass: "row justify-content-center",
    staticStyle: {
      "padding-right": "1em",
      "padding-left": "1em"
    }
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('el-form', {
    ref: "ruleForm",
    attrs: {
      "model": _vm.inputFormData,
      "rules": _vm.rules
    },
    nativeOn: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.checkEnter.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form_input_container"
  }, [_c('el-form-item', {
    attrs: {
      "label": "Group Name",
      "prop": "name"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text",
      "placeholder": "Enter group name",
      "autocomplete": "false"
    },
    model: {
      value: _vm.inputFormData.name,
      callback: function ($$v) {
        _vm.$set(_vm.inputFormData, "name", $$v);
      },
      expression: "inputFormData.name"
    }
  })], 1)], 1), _vm.errors.length ? _c('span', _vm._l(_vm.errors, function (error, key) {
    return _c('Alert', {
      key: key,
      attrs: {
        "message": error,
        "type": _vm.type
      }
    });
  }), 1) : _vm._e(), _vm.success ? _c('Alert', {
    attrs: {
      "message": _vm.success,
      "type": "alert-success"
    }
  }) : _vm._e()], 1)], 1)]), _c('div', {
    staticClass: "modal__footer__btns"
  }, [_c('button', {
    staticClass: "cancel",
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "loading": _vm.loading,
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.createNewGroup('ruleForm');
      }
    }
  }, [_vm._v(_vm._s(_vm.groupForm.btnTitle))])], 1)])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };